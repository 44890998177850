/* eslint-disable */
export default {
  hj1: '首頁',
  hj2: '餘額',
  hj3: '可交換的',
  hj4: '鏈上餘額',
  hj5: '查看獎勵',
  hj6: '提現',
  hj7: '兌換',
  hj8: '全部',
  hj9: '活動獎勵',
  hj10: '兌換獎勵',
  hj11: '提現記錄',
  hj12: '已稽核',
  hj13: '提現金額',
  hj14: '獎勵數量',
  hj15: '數量',
  hj16: '匯率',
  hj17: '幫助中心',
  hj18: '選擇語言',
  hj19: '已參加',
  hj20: '進行中',
  hj21: '領取',
  hj22: '帳號',
  hj23: '活動',
  hj24: '收益記錄',
  hj25: '白皮書',
  hj26: '幫助中心',
  hj27: '選擇語言',
  hj28: '總收益',

  hj29: '您將接受高達',
  hj30: '獎勵',
  hj31: '規則',
  hj32: '結束',
  hj33: '獎勵標準',
  hj34: '合約金額',
  hj35: '說明',
  hj36: '總獎勵',
  hj37: '合約獎勵',

  hj38: '公告',
  hj39: '關閉',
  hj40: '合作夥伴',
  hj41: '立即加入',
  hj42: '參與用戶',
  hj43: '用戶收益',
  hj44: '節點數',
  hj45: '兌換明細',
  hj46: '提現明細',
  hj47: '節點明細',
  hj48: '活動明細',
  hj49: '參加活動以解鎖獎勵',
  hj50: '目標',
  hj51: '查看明細',
  hj52: '查看獎勵',
  hj53: '帳號'
};
