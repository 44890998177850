<template>
  <div class="home" style="position: relative">
    <top-fixed></top-fixed>
    <div class="center_box">
      <div class="top_box">
        <div style="display: flex; height: 1rem; overflow: hidden">
          <img class="left_img" src="../assets/img2/home_left.png" />
          <div
            class="swiper-container2"
            style="border-radius: 10px; margin-left: 0.4rem"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in noticeData"
                :key="index"
              >
                <div
                  style="font-size: 0.5rem; padding-top: 0.2rem"
                  @click="$router.push('/notice')"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          class="right_img"
          src="../assets/img2/home_right.png"
          @click="$router.push('/notice')"
        />
      </div>

      <div class="cent_box">
        <div
          class="cent_content"
          style="margin-left: auto; margin-bottom: 0.7rem; margin-right: 8px"
        >
          <div style="font-size: 0.5rem; margin-bottom: 2px">
            {{ address1 }}
          </div>
          <div style="font-size: 0.6rem; font-weight: 600">
            {{ addressValue1 }} ETH
          </div>
        </div>
        <div class="cent_content" style="margin-bottom: 0.6rem">
          <div style="font-size: 0.5rem; margin-bottom: 2px">
            {{ address2 }}
          </div>
          <div style="font-size: 0.6rem; font-weight: 600">
            {{ addressValue2 }} ETH
          </div>
        </div>
        <div
          class="cent_content"
          style="margin-left: auto; margin-right: 0.7rem"
        >
          <div style="font-size: 0.5rem; margin-bottom: 2px">
            {{ address3 }}
          </div>
          <div style="font-size: 0.6rem; font-weight: 600">
            {{ addressValue3 }} ETH
          </div>
        </div>
      </div>

      <div class="cent_box2">
        <div class="box2_content">
          <img src="../assets/img2/cent_box1.png" />
          <div style="font-size: 1rem; margin-bottom: 5px">
            {{ homeNumber }}
          </div>
          <div style="font-size: 0.6rem">{{ $t("hj44") }}</div>
        </div>
        <div class="box2_content" style="position: relative; top: -1.6rem">
          <img src="../assets/img2/cent_box2.png" />
          <div style="font-size: 1rem; margin-bottom: 5px">
            {{ homeNumber2 }}
          </div>
          <div style="font-size: 0.6rem">{{ $t("hj43") }}</div>
        </div>
        <div class="box2_content">
          <img src="../assets/img2/cent_box3.png" />
          <div style="font-size: 1rem; margin-bottom: 5px">
            {{ homeNumber3 }}
          </div>
          <div style="font-size: 0.6rem">{{ $t("hj42") }}</div>
        </div>
      </div>

      <div class="cent_but" @click="checkReg">{{ $t("hj41") }}</div>
      <div style="position: relative; top: 1rem; width: 96%; margin-left: 2%">
        <div class="swiper-container" style="border-radius: 10px">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in bannerList"
              :key="index"
            >
              <img
                @click="gouUrl(item)"
                style="width: 100%; height: 100%"
                :src="item.imgurl"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="top_box"
      style="background: #ffe0b4; padding-top: 4rem; padding-bottom: 2rem"
    >
      <div
        style="
          color: #fff;
          font-size: 1rem;
          text-align: center;
          margin-bottom: 1rem;
        "
      >
        <span
          style="
            display: inline-block;
            width: 3rem;
            height: 1px;
            background: #fff;
            position: relative;
            top: -0.3rem;
          "
        >
        </span>
        {{ $t("hj40") }}
        <span
          style="
            display: inline-block;
            width: 3rem;
            height: 1px;
            background: #fff;
            position: relative;
            top: -0.3rem;
          "
        ></span>
      </div>
      <div style="background: #ffe6c3; padding: 1.2rem 0.6rem; margin: 0 1rem">
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
          "
        >
          <div style="width: 3rem">
            <img style="width: 3rem" src="../assets/img2/Binance.png" />
            <div
              style="
                color: #000;
                font-size: 0.6rem;
                font-weight: 600;
                text-align: center;
              "
            >
              Binance
            </div>
          </div>

          <div style="width: 3rem">
            <img style="width: 3rem" src="../assets/img2/Certik.png" />
            <div
              style="
                color: #000;
                font-size: 0.6rem;
                font-weight: 600;
                text-align: center;
              "
            >
              Certik
            </div>
          </div>

          <div style="width: 3rem">
            <img style="width: 3rem" src="../assets/img2/Coinbase.png" />
            <div
              style="
                color: #000;
                font-size: 0.6rem;
                font-weight: 600;
                text-align: center;
              "
            >
              Coinbase
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
          "
        >
          <div style="width: 3rem">
            <img style="width: 3rem" src="../assets/img2/CoinGecko.png" />
            <div
              style="
                color: #000;
                font-size: 0.6rem;
                font-weight: 600;
                text-align: center;
              "
            >
              CoinGecko
            </div>
          </div>

          <div style="width: 3rem">
            <img style="width: 3rem" src="../assets/img2/Crypto.com.png" />
            <div
              style="
                color: #000;
                font-size: 0.6rem;
                font-weight: 600;
                text-align: center;
              "
            >
              Crypto.com
            </div>
          </div>

          <div style="width: 3rem">
            <img style="width: 3rem" src="../assets/img2/Defibox.png" />
            <div
              style="
                color: #000;
                font-size: 0.6rem;
                font-weight: 600;
                text-align: center;
              "
            >
              Defibox
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
          "
        >
          <div style="width: 3rem">
            <img style="width: 3rem" src="../assets/img2/Fairyproof.png" />
            <div
              style="
                color: #000;
                font-size: 0.6rem;
                font-weight: 600;
                text-align: center;
              "
            >
              Fairyproof
            </div>
          </div>

          <div style="width: 3rem">
            <img style="width: 3rem" src="../assets/img2/Huobi.png" />
            <div
              style="
                color: #000;
                font-size: 0.6rem;
                font-weight: 600;
                text-align: center;
              "
            >
              Huobi
            </div>
          </div>

          <div style="width: 3rem">
            <img style="width: 3rem" src="../assets/img2/Slowmist.png" />
            <div
              style="
                color: #000;
                font-size: 0.6rem;
                font-weight: 600;
                text-align: center;
              "
            >
              Slowmist
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="noticeShow"
      class="notic_box"
      style="
        position: fixed;
        width: 300px;
        height: min-content;
        top: -10px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 1000;
        padding: 0 10px;
        background: #fff;
        border-radius: 20px;
      "
    >
      <div
        style="
          text-align: center;
          position: relative;
          top: 25px;
          font-size: 0.8rem;
        "
      >
        {{ $t("hj38") }}
      </div>
      <img
        @click="showUserNotic"
        src="../assets/img/X.png"
        style="
          position: absolute;
          width: 20px;
          height: 20px;
          top: 20px;
          left: 290px;
        "
      />
      <div
        class="swiper-container notic_box"
        style="
          overflow: auto;
          height: 180px;
          margin-top: 50px;
          padding: 2rem 1rem;
        "
      >
        <!-- <img
          style="width: 80px; float: right; top: -14px; position: relative"
          src="../assets/img2/T.png"
        /> -->
        <div class="swiper-wrapper" style="width: 100%">
          <div
            class="swiper-slide"
            v-for="(item, index) in noticeData"
            :key="index"
          >
            <img style="width: 100%" :src="item.imgurl" />
          </div>
        </div>
      </div>

      <div
        @click="showUserNotic"
        style="
          text-align: center;
          color: #000;
          width: 150px;
          line-height: 32px;
          margin: 0 auto;
          border-radius: 10px;
          margin-top: 10px;
          font-size: 0.8rem;
          padding-bottom: 10px;
        "
      >
        {{ $t("hj39") }}
      </div>
    </div>

    <div
      v-if="noticeShows"
      class="notic_box"
      style="
        position: fixed;
        width: 300px;
        height: min-content;
        top: -10px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 1000;
        padding: 0 10px;
        background: #fff;
        border-radius: 20px;
      "
    >
      <div
        style="
          text-align: center;
          position: relative;
          top: 25px;
          font-size: 0.8rem;
        "
      >
        {{ $t("hj38") }}
      </div>
      <img
        @click="noticeShows = false"
        src="../assets/img/X.png"
        style="
          position: absolute;
          width: 20px;
          height: 20px;
          top: 20px;
          left: 290px;
        "
      />
      <div
        class="swiper-container notic_box"
        style="
          overflow: auto;
          height: 180px;
          margin-top: 50px;
          padding: 2rem 1rem;
        "
      >
        <!-- <img
          style="width: 80px; float: right; top: -14px; position: relative"
          src="../assets/img2/T.png"
        /> -->
        <div class="swiper-wrapper" style="width: 100%">
          <div
            class="swiper-slide"
            v-for="(item, index) in noticeDatas"
            :key="index"
          >
            <img style="width: 100%" :src="item.imgurl" />
          </div>
        </div>
      </div>

      <div
        @click="noticeShows = false"
        style="
          text-align: center;
          color: #000;
          width: 150px;
          line-height: 32px;
          margin: 0 auto;
          border-radius: 10px;
          margin-top: 10px;
          font-size: 0.8rem;
          padding-bottom: 10px;
        "
      >
        {{ $t("hj39") }}
      </div>
    </div>

    <a
      class="kefu"
      :href="kf_url"
      style="position: fixed; top: 80%; z-index: 1000; width: 100px; left: 80%"
    >
      <div
        id="fixedbutton"
        class="float_cs_btn"
        style="
          box-shadow: 0 2px 10px #0000001a;
          border-radius: 50%;
          color: #121212;
          z-index: 98;
          transition: all 0.3s;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: fixed;
          bottom: 20vw;
          width: 2.6rem;
          height: 2.6rem;
        "
      ></div>
    </a>

    <div
      v-if="noticeShows || noticeShow"
      class="zz"
      style="
        z-index: 100;
        position: absolute;
        background: #969799;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0.4;
      "
    ></div>
  </div>
</template>

<script>
import { Swiper } from 'vue-awesome-swiper';
import checkReg from '../assets/js/main.js'
import 'swiper/swiper-bundle.css';
import { swipe, ethusdt, user_notice, user_notices, joinActivity, invitecode } from '../http/api'
import TopFixed from '../components/TopFixed.vue';
export default {
  components: { TopFixed },
  data () {
    return {
      bannerList: [],
      noticeData: '',
      noticeDatas: '',
      noticeShow: false,
      noticeShows: false,
      homeNumber: 5045451,
      homeNumber2: 3015451,
      homeNumber3: 4038451,
      address1: '',
      addressValue1: 0.0751,
      addressValue2: 0.0231,
      addressValue3: 0.0431,
      address2: '',
      address3: "",
      Interval: false,
      text: [],
      kf_url: ''
    }

  },
  mounted () {
    this.fetchBanner()
    this.dataInit()
    invitecode().then((res) => {
      this.kf_url = res.data.kf_url
    })
    if (!this.Interval) {
      this.Interval = setInterval(this.getAddress, 1000)
    }
  },
  methods: {
    checkReg () {
      checkReg()
    },
    getAddress () {
      var address1 = address1 || 32;
      var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      var maxPos = $chars.length;
      var pwd = '';
      for (var i = 0; i < address1; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      this.address1 = (pwd.substring(0, 5)) + '....' + (pwd.substring(20))
      this.address2 = (pwd.substring(5, 10)) + '....' + (pwd.substring(20))
      this.address3 = (pwd.substring(10, 15)) + '....' + (pwd.substring(20))
      this.addressValue1 = '0.0' + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10)
      this.addressValue2 = '0.0' + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10)
      this.addressValue3 = '0.0' + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10)
      this.homeNumber = this.homeNumber + Math.floor(Math.random() * 10)
      this.homeNumber2 = this.homeNumber2 + Math.floor(Math.random() * 10)
      this.homeNumber3 = this.homeNumber3 + Math.floor(Math.random() * 10)

    },
    fetchBanner () {
      swipe().then(res => {
        if (res.data) {
          this.bannerList = res.data;
          setTimeout(() => {
            new Swiper(".swiper-container", {
              autoplay: true
            });
          }, 1000);
        }
      })
    },
    dataInit () {
      ethusdt().then((res) => {
        window.localStorage.setItem("exchange_rate", res.data.exchange_rate)
      })
      user_notice().then((res) => {
        if (res.code != 401 && (res.code == 1 && res.data.length != 0)) {
          this.noticeShow = true
          this.noticeData = res.data

          setTimeout(() => {
            new Swiper(".swiper-container2", {
              autoplay: true,
              direction: 'vertical',
            });
          }, 1000);
        }
      })
    },

    showUserNotic () {
      this.noticeShow = false
      user_notices().then((res) => {
        if (res.code != 401 && (res.code == 1 && res.data.length != 0)) {
          this.noticeShows = true

          this.noticeDatas = res.data

        }
      })
    },

    toActive () {
      joinActivity({ activity_id: this.noticeDatas.act_id }).then((res) => {
        this.$router.push({
          path: "/activitDea",
          query: {
            id: this.noticeDatas.act_id
          }
        })
      })
    },

    gouUrl (item) {
      window.open(item.url)
    }
  }
};
</script>

<style scoped lang="scss" >
.swiper-container {
  /* width: 600px; */
  height: 8rem;
}
.home {
  overflow: hidden;
}
.center_box {
  background: url("../assets/img2/homeBackground.png");
  height: 1333px;
  background-size: 100% 100%;
  .top_box {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    .left_img {
      height: 40px;
    }
    .right_img {
      height: 28px;
    }
  }
  .cent_box {
    .cent_content {
      width: 344px;
      height: 50px;
      padding: 15px;
      background: url("../assets/img2/home3.png");
      color: #000000;
      text-align: center;
      background-size: 100% 100%;
    }
  }
}
.cent_box2 {
  display: flex;
  margin-top: 1.1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  .box2_content {
    color: #fff;
    text-align: center;
    width: 30%;
    img {
      width: 100%;
    }
  }
}
.cent_but {
  background: url("../assets/img2/home_but.png");
  background-size: 100% 100%;
  line-height: 110px;
  width: 360px;
  margin: 0 auto;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
}
.notic_box {
  background: url("../assets/img2/Account_bgs.png");
  background-size: 100% 100%;
  border-bottom: 1px solid #dddddd;
}
.float_cs_btn {
  background: url("../assets/img2/kf.png");
  background-size: 100% 100%;
}
</style>
