<template>
  <div>
    <div class="top_box">
      <img
        @click="newShow = !newShow"
        class="left_img"
        src="../assets/img2/top1.png"
      />
      <img class="right_img" src="../assets/img2/top2.png" />
    </div>

    <div
      style="position: absolute; background: #fff; width: 100%; z-index: 2"
      v-show="newShow"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0.5rem 1rem;
        "
      >
        <div
          :class="nowIndex == 1 ? 'change' : 'noChange'"
          @click="change(1)"
          style="width: 2rem; border-radius: 0.4rem; padding: 0.5rem 0.7rem"
        >
          <img
            v-if="nowIndex == 1"
            src="../assets/img2/header7.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <img
            v-else
            src="../assets/img2/header1.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />

          <div style="text-align: center; font-size: 0.5rem">
            {{ $t("hj1") }}
          </div>
        </div>

        <div
          :class="nowIndex == 2 ? 'change' : 'noChange'"
          @click="change(2)"
          style="width: 2rem; border-radius: 0.4rem; padding: 0.5rem 0.7rem"
        >
          <img
            v-if="nowIndex == 2"
            src="../assets/img2/header8.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <img
            v-else
            src="../assets/img2/header2.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <div style="text-align: center; font-size: 0.5rem">
            {{ $t("hj53") }}
          </div>
        </div>

        <div
          :class="nowIndex == 3 ? 'change' : 'noChange'"
          @click="change(3)"
          style="width: 2rem; border-radius: 0.4rem; padding: 0.5rem 0.7rem"
        >
          <img
            v-if="nowIndex == 3"
            src="../assets/img2/header9.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <img
            v-else
            src="../assets/img2/header3.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <div style="text-align: center; font-size: 0.5rem">
            {{ $t("hj23") }}
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0.5rem 1rem;
        "
      >
        <div
          @click="change(4)"
          :class="nowIndex == 4 ? 'change' : 'noChange'"
          style="width: 2rem; border-radius: 0.4rem; padding: 0.5rem 0.7rem"
        >
          <img
            v-if="nowIndex == 4"
            src="../assets/img2/header10.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <img
            v-else
            src="../assets/img2/header4.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <div style="text-align: center; font-size: 0.5rem">
            {{ $t("hj24") }}
          </div>
        </div>
        <div
          :class="nowIndex == 5 ? 'change' : 'noChange'"
          @click="change(5)"
          style="width: 2rem; border-radius: 0.4rem; padding: 0.5rem 0.7rem"
        >
          <img
            v-if="nowIndex == 5"
            src="../assets/img2/header0.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <img
            v-else
            src="../assets/img2/header.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <div style="text-align: center; font-size: 0.5rem">
            {{ $t("hj25") }}
          </div>
        </div>
        <div
          :class="nowIndex == 6 ? 'change' : 'noChange'"
          @click="change(6)"
          style="width: 2rem; border-radius: 0.4rem; padding: 0.5rem 0.7rem"
        >
          <img
            v-if="nowIndex == 6"
            src="../assets/img2/header11.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <img
            v-else
            src="../assets/img2/header5.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <div style="text-align: center; font-size: 0.5rem">
            {{ $t("hj26") }}
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0.5rem 1rem;
        "
      >
        <div
          style="width: 2rem; border-radius: 0.4rem; padding: 0.5rem 0.7rem"
          :class="nowIndex == 7 ? 'change' : 'noChange'"
          @click="change(7)"
        >
          <img
            v-if="nowIndex == 7"
            src="../assets/img2/header12.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <img
            v-else
            src="../assets/img2/header6.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <div style="text-align: center; font-size: 0.5rem">
            {{ $t("hj27") }}
          </div>
        </div>

        <!-- <div
          :class="nowIndex == 8 ? 'change' : 'noChange'"
          @click="change(8)"
          style="width: 2rem; border-radius: 0.4rem; padding: 0.5rem 0.7rem"
        >
          <img
            v-if="nowIndex == 8"
            src="../assets/img2/header14.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <img
            v-else
            src="../assets/img2/header13.png"
            style="width: 100%; margin-bottom: 0.2rem"
          />
          <div style="text-align: center; font-size: 0.5rem">兑换币种</div>
        </div> -->

        <div style="width: 2rem; padding: 0.5rem 0.7rem"></div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "TopFixed",
  data () {
    return {
      show: false,
      nowIndex: 1,
      newShow: false,
    };
  },
  mounted () {
    console.log(this.$route.path)
    switch (this.$route.path) {
      case '/':
        this.nowIndex = 1
        break
      case '/account':
        this.nowIndex = 2
        break
      case '/activity':
        this.nowIndex = 3
        break
      case '/reward':
        this.nowIndex = 4
        break
      case '/whitebook':
        this.nowIndex = 5
        break
      case 'help':
        this.nowIndex = 6
        break
      case '/language':
        this.nowIndex = 7
        break
    }
  },
  methods: {
    change (item) {
      this.nowIndex = item
      switch (item) {
        case 1:
          this.$router.push('/')
          break
        case 2:
          this.$router.push('/account')
          break
        case 3:
          this.$router.push('./activity')
          break
        case 4:
          this.$router.push('/reward')
          break
        case 5:
          this.$router.push('/whitebook')
          break
        case 6:
          this.$router.push('./help')
          break
        case 7:
          this.$router.push('./language')

      }
    }
  },
};
</script>

<style scoped lang="scss">
.top_box {
  display: flex;
  justify-content: space-between;
  padding: 30px 40px;
  .left_img {
    height: 44px;
  }
  .right_img {
    height: 48px;
  }
}

.change {
  border: 1px solid #fa9d45;
  color: #fa9d45;
}
.noChange {
  border: 1px solid #dddddd;
}
</style>