import axios from './axios';

export function swipe() {
  return axios({
    method: 'GET',
    url: '/api/mining/swipe'
  });
}

export function invitecode() {
  return axios({
    method: 'GET',
    url: '/api/support'
  });
}

export function mining_stake(params) {
  return axios({
    method: 'POST',
    url: '/api/user/check_reg',
    data: params
  });
}
//帮助中心
export function help() {
  return axios({
    method: 'GET',
    url: '/api/mining/help'
  });
}

//账户信息
export function account() {
  return axios({
    method: 'GET',
    url: '/api/user/account'
  });
}
//活动
export function activity() {
  return axios({
    method: 'GET',
    url: '/api/mining/activity'
  });
}

// 奖励
export function reward(data) {
  return axios({
    method: 'GET',
    url: '/api/user/award_log?op_type=' + data.type
  });
}

//提现记录

export function withdraw_logs(data) {
  return axios({
    method: 'GET',
    url: '/api/user/withdraw_logs'
  });
}

// 汇率
export function ethusdt(data) {
  return axios({
    method: 'GET',
    url: '/api/support/ethusdt'
  });
}

//提现
export function withdraw(data) {
  return axios({
    method: 'POST',
    url: '/api/user/withdraw',
    data: data
  });
}

//兑换
export function exchange(data) {
  return axios({
    method: 'POST',
    url: '/api/user/exchange',
    ...data
  });
}

//参加活动
export function joinActivity(params) {
  return axios({
    method: 'POST',
    url: '/api/mining/join?activity_id=' + params.activity_id
  });
}

//活动明细
export function act_detail(params) {
  return axios({
    method: 'GET',
    url: '/api/mining/act_detail?activity_id=' + params
  });
}

//通用公告
export function user_notice(params) {
  return axios({
    method: 'GET',
    url: '/api/mining/notice'
  });
}

//用户公告
export function user_notices(params) {
  return axios({
    method: 'GET',
    url: '/api/mining/user_notice'
  });
}

//质押币种列表
export function staked_coin(params) {
  return axios({
    method: 'GET',
    url: '/api/mining/staked_coin',
    data: params
  });
}
